<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="form.id ? $t('Изменить') : $t('Добавить')"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
      >
        <b-form-group
          :label="$t('Имя')"
          label-for="number"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Biriktirilgan harf')"
            rules="required"
          >
            <b-form-input
              id="number"
              v-model="form.number"
              :state="errors.length > 3 ? false:null"
              name="number"
              disabled
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Foydalanuvchi')"
          label-for="role"
        >
          <validation-provider
            #default="{ errors }"
            name="role_id"
            rules="required"
          >
            <b-form-select
              id="role"
              v-model="form.user_id"
              :state="errors.length > 0 ? false:null"
              name="role"
              value-field="id"
              text-field="name"
              :placeholder="$t('Foydalanuvchini tanlang')"
            >
              <b-form-select-option
                v-for="item in usersList.data"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }} ({{ item.role.name }})
              </b-form-select-option>
            </b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver,
  },
  props: {},
  data() {
    return {
      form: {
        id: null,
        number: '',
        user: '',
        user_id: null,
      },
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    ...mapGetters({ usersList: 'user/GET_ITEMS' }),
  },
  watch: {
    visible(newVal) {
      if (!newVal) setTimeout(() => { clearObject(this.form) }, 200)
    },
  },
  mounted() {
    this.$root.$on('add', data => {
      this.visible = true
    })
    this.getUsersList({ per_page: 999, relations: 'role' })
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess')
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      this.form.id = item.id
      this.form.number = item.number
      this.form.user = item.user
      this.form.user_id = item.user_id
      this.visible = true
    },
    method(data) {
      // if (this.form.id) return this.updateItem(data)
      return this.updateItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      getUsersList: 'user/index',
      storeItem: 'attachLetter/storeNum',
      updateItem: 'attachLetter/updateNum',
      getPermissions: 'resource/getPermissions',
    }),
  },
}
</script>

<style scoped>

</style>
